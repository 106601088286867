export default {

    //Mettre en capital chaque premiere lettre de mot
    StrCapital: function( str )
    {
        if(!str)
            return '';
            
        if( typeof(str) == 'string' )
        return str.split(' ').map(function(mot)
        {
            return mot.charAt(0).toUpperCase() + mot.slice(1).toLowerCase();
        }).join(' ');
        return '';
    },

    //Format des code postaux
    PostalCodeFormat(cp)
    {
        if( cp == undefined )
            return undefined;

        if( !cp )
            return 0;

        let s_cp = cp.toString();
        let size = s_cp.length;
        for( var i= size; i<5; i++ )
                s_cp = '0'+s_cp;

        return s_cp;
    },


    GetDevise( device )
    {
        let ret = '?';
        switch(devise)
        {
            case 'EUR':/*Euro*/
                ret = '€';
            break;
            case 'MAD':/*Maroc*/
                ret = 'MAD';
            break;
            case 'CHF':/*Suisse*/
                ret = 'CHF';
            break;
            case 'TES':/*Pour test Special*/
                ret = '!#';
            break;
        };
        return " "+ret;
    },


    Millier:function(num)
    {
        if( num == undefined )
            return '?';

        if( (typeof num) == 'string' )
        {
            num = Number(num);
        }

        num = num.toFixed(2);
        num = num.toString();
        return ("" + num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function($1) { return $1 + " " });
    },


    isFunction(functionToCheck)
    {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    },


    ObjLength(obj)
    {
        var size = 0, key;
        for (key in obj)
        {
            obj.hasOwnProperty(key);//eviter erreur
            size++;
        }
        return size;
    },



    removeDuplicate( tab )
    {
        let unique = {};
        tab.forEach(function(i)
        {
          if(!unique[i])
            unique[i] = true;
        });
        return Object.keys(unique);
    },


    clone( obj )
    {
        switch( typeof(obj) )
        {
            case 'object':
            case 'array':
                let rep = {};
                //clean
                for( var c in obj )
                    rep[c] = obj[c]
        
                //forcer la rupture de mémoire
                return JSON.parse(JSON.stringify(rep));
            break;

            case 'string':
                return (' ' + obj).slice(1);
            break;
        };
    },



    roundDecimal(nombre, precision)
    {
        var precision = precision || 2;
        var tmp = Math.pow(10, precision);
        return Math.round( nombre*tmp )/tmp;
    },

    NumberTwoVirgule( nbr )
    {
        return Math.round((nbr + Number.EPSILON) * 100) / 100
    },

    async ToAwait( callback )
    {
        return new Promise(async (resolve)=>{
            callback( async (data)=>{
                return resolve(data);
            });
        })
    }
}