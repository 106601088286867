/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

//------------------------------------------------------------
// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


//------------------------------------------------------------
// axios
import axios from "./axios.js"
Vue.prototype.$http = axios

//------------------------------------------------------------
// Theme Configurations
import '../themeConfig.js'


//------------------------------------------------------------
// Globally Registered Components
import './globalComponents.js'


//------------------------------------------------------------
// Styles: SCSS
import './assets/scss/main.scss'


//------------------------------------------------------------
// Tailwind
import '@/assets/css/main.css'


//------------------------------------------------------------
// Vue Router
import router from './router'


//------------------------------------------------------------
// Vuex Store
import store from './store/store'


//------------------------------------------------------------
// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


//------------------------------------------------------------
// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


//------------------------------------------------------------
// Feather font icon
require('./assets/css/iconfont.css')


//------------------------------------------------------------
// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );


//------------------------------------------------------------
// vue-signature-pad for signature
// https://vuejsexamples.com/vue-signature-pad-component/
import VueSignature from "vue-signature-pad"
Vue.use(VueSignature);

//------------------------------------------------------------
// VueTelInput for phone
import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput,{
  placeholder:"Numéro de téléphone portable",
  mode:'international',
})

//------------------------------------------------------------
//Refresh BDD
import bddRefresh from '@/database/bddRefresh';
Vue.use(bddRefresh)

//------------------------------------------------------------
//Add notification
import notify from '@/assets/utils/notify';

import './registerServiceWorker'
Vue.use(notify)

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


//------------------------------------------------------------
//Custom design
import '@/style.css'

//------------------------------------------------------------
//NetWork serveur + load config salle
import srvApi from '@/srvApi';

axios.get('/config.js')
.then((response )=>{
    let config = response.data
    
    //------------------------------------------------------------
    ////NetWork serveur
    Vue.use(srvApi,{
      studio  : config.uid,
      terminal: config.terminal
    })


    //------------------------------------------------------------
    Vue.config.productionTip = false

    const vuejs = new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')

    //------------------------------------------------------------
    //?? Bug bizar ne marche pas en input normal
    vuejs.$notify.setVue( vuejs );
})