import Sdata from '@/database/sdata'


let g_entity = 'member'
let g_url    = 'studio/member'
let g_pkey   = ['uid']
Sdata.addConfig( g_entity, g_pkey, g_url )


export default class Member extends Sdata
{
  static entity = g_entity
  static url    = g_url

  
  //-------------------------------------------------------------
  /* to get Utilisateur Role */
  //-------------------------------------------------------------
  static getAuthName( input_data )
  {
    if(input_data == null )
      return '?';

    let rep = '?';
    switch( input_data.type )
    {
      case 'i':
        rep = 'Isidoors';
      break;
      case 'a':
        rep = 'Admin';
      break;
      case 'u':
        rep = 'Utilisateur';
      break;
      case 'c':
        rep = 'Comptable';
      break;
      case 'e':
        rep = 'Essai';
      break;
      case 'm':
        rep = 'Membre';
      break;
    }
    return rep;
  }

  //-------------------------------------------------------------
  /* to get Utilisateur Etat */
  //-------------------------------------------------------------
  static getEtatName( input_data )
  {
    if(input_data == null )
      return '?';

    let rep = '?';
    switch( Number(input_data.active) )
    {
      case 1:
        rep = "Actif";
      break;
      case 0:
        rep = "Inactif";
      break;
      case -1:
        rep = "Suspendu";
      break;
    }

    return rep;
  }


  //-------------------------------------------------------------
  /* to get Utilisateur Gender */
  //-------------------------------------------------------------
  static getGender( input_data )
  {
    if(input_data == null )
      return '?';

    let rep = '?';
    switch( input_data.gender )
    {
      case 'f':
        rep = "Femme";
      break;
      case 'm':
        rep = "Homme";
      break;
    }
    return rep;
  }


  //-------------------------------------------------------------
  /* get color */
  //-------------------------------------------------------------
  static color = {
    essai        :'rgb(109, 173, 249)',
    contractuel  :'rgb(13, 33, 155)',
    actif        :'rgb(92, 184, 92)',
    suspendu     :'rgb(240, 173, 78)',
    inactif      :'rgb(217, 83, 79)',
    '?'          :'rgb(30, 30, 30)',

    fessai       :'rgb(62, 98, 140)',
    fcontractuel  :'rgb(13, 33, 155)',
    factif       :'rgb(50, 99, 50)',
    fsuspendu    :'rgb(145, 104, 47)',
    finactif     :'rgb(120, 45, 43)',
    'f?'         :'rgb(80, 80, 80)',
  }


  //-------------------------------------------------------------
  /* get type */
  //-------------------------------------------------------------
  static getTypeSpec( mbr )
  {
    //Essai
    if(mbr.type == 'e')
    if(Number(mbr.active) == 1)
      return 'essai';
    //Contractuel
    if(mbr.type == 'c')
    if(Number(mbr.active) == 1)
      return 'contractuel';
    //Actif
    if(mbr.type == 'm')
    if(Number(mbr.active) == 1)
      return 'actif';
    //Suspendu
    if(Number(mbr.active) == -1)
      return 'suspendu';
    //Inactif
    if(Number(mbr.active) == 0)
      return 'inactif'

    return '?';
  }


  static ficheMinComplete( uid )
  {
    let root = this
    return new Promise((resolve, reject)=>
    {
      root.getMemory( uid, (mbr)=>
      {
        if(!mbr.first_name)
            return reject('nom vide')

        if(!mbr.last_name)
            return reject('prenom vide')

        if(!mbr.phone)
            return reject('téléphone vide')

        if(!mbr.email)
            return reject('email vide')

        //if(!mbr.birthday)
        //    return reject()

        if(mbr.gender=='?')
           return reject('Aucun genre')

        if(!mbr.address)
            return reject('adresse vide')

        if(!mbr.city)
            return reject('aucune ville')

        if(!mbr.postal_code)
            return reject('code postal vide')
            
        return resolve()
      })
    })
  }


}
