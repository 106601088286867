import _Vue from 'vue';

export default {

    //Install Vue plugin
    install (Vue)
    {
        let root_vue = null;


        //=================================================================
        //Création de l'objet Notify
        //=================================================================
        Vue.prototype.$notify = {

            //-------------------------------------------------------------
            /*Set ROOT obligatoire */
            //-------------------------------------------------------------
            setVue:function( svue )
            {
                root_vue = svue;
            },
            

            //-------------------------------------------------------------
            /*Notification success */
            //-------------------------------------------------------------
            success:function( input_title, input_message="" )
            {
                root_vue.$vs.notify({
                    title:input_title,
                    text:input_message,
                    color:'success',
                    position:'bottom-right',
                    time:4000,
                  });
            },


            //-------------------------------------------------------------
            /*Notification error */
            //-------------------------------------------------------------
            error:function( input_title, input_message="" )
            {
                root_vue.$vs.notify({
                    title:input_title,
                    text:input_message,
                    color:'warning',
                    position:'bottom-right',
                    time:4000,
                  });
            },


            //-------------------------------------------------------------
            /*Notification en attante */
            //-------------------------------------------------------------
            stand:function( input_title, input_message="" )
            {
                root_vue.$vs.notify({
                    title:input_title,
                    text:input_message,
                    color:'primary',
                    position:'bottom-right',
                    time:4000,
                  });
            },
        };
    }
}