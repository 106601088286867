import Sdata from '@/database/sdata'

let g_entity = 'member_booking'
let g_url    = 'studio/booking'
let g_pkey   = ['uid','terminal','member',{name:'date',fonc:byDay}];
Sdata.addConfig( g_entity, g_pkey, g_url )


export default class Booking extends Sdata
{
  static entity = g_entity
  static url    = g_url
  static pkey   = g_pkey


  //renvoi le nom fu type de réservation
  static getTypeName( type )
  {
    let rep = '?';
    switch(type)
    {
      case 'e':
        rep = 'essai';
      break;
      case 'c':
        rep = 'contractuel';
      break;
      case 's':
        rep = 'réservation';
      break;
      case 'm':
        rep = 'multi-studio';
      break;
      case 'b':
        rep = 'fermer';
      break;
    }
    return rep;
  }


  //get booking color
  static getColor( type )
  {
    let rep = '?';
    switch(type)
    {
      case 'e':
      case 'c':
        rep = 'rgb(109, 173, 249)';
      break;
      case 's':
        rep = 'rgb(92, 184, 92)';
      break;
      case 'm':
        rep = 'rgb(156, 154, 59)';
      break;
      case 'b':
        rep = 'rgb(207, 23, 23)';
      break;
    }
    return rep;
  }

}

function byDay( input_data )
{
  let d = new Date( input_data.date );

  let year  = d.getFullYear();
  let month = d.getMonth()+1;
  let day   = d.getDate();
  if(month<10)
    month = '0'+month;
  if(day<10)
    day = '0'+day;

  return year+'/'+month+'/'+day;
}